import styled from "styled-components";

export const Wrapper = styled.div`
	margin: 50px 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	h1 {
		color: #1ca7ec;
	}
	@media screen and (max-width: 460px) {
		h1 {
			font-size: 24px;
		}
	}
`;
export const FeaturesContainer = styled.div`
	width: 98%;
	max-width: 98%;
	margin: 0 auto;
	display: flex;
	justify-content: space-around;
	padding: 30px 40px;

	@media screen and (max-width: 468px) {
		flex-direction: column;
	}
`;

export const Feature = styled.div`
	width: 23%;
	min-height: 150px;
	border: 1px solid #1ca7ec;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	padding: 30px 10px;
	border-radius: 10px;
	transition: 0.3s;

	svg {
		/* padding: 10px; */
		width: 28%;
		height: 28%;
	}
	path {
	}
	p {
		margin: 0;
		font-size: 18px;
		/* font-weight: 400; */
		font-weight: 700;
	}

	:hover {
		box-shadow: 0px 0px 5px 5px lightgrey;
	}
	@media screen and (max-width: 468px) {
		/* flex-direction: column; */
		width: 100%;
		padding: 10px;
		margin: 30px 0;
		svg {
			/* padding: 10px; */
			width: 15%;
			height: 15%;
		}

		p {
			font-size: 14px;
		}
	}
`;
