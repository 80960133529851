import React from "react";
import { FooterParent } from "./Footer.style";
import { SocialIcon } from "react-social-icons";
import "./footer.css";

const Footer = () => {
  return (
    <FooterParent>
      <div className="footer-top-section section">
        <div className="top-first">
          <p className="location-title">
            THE INSOMNIA AND SLEEP INSTITUTE OF ARIZONA
          </p>
          <p className="location-title"> SCOTTSDALE</p>
          <div className="location-address">
            <p>8330 E Hartford Drive, Suite 100</p>
            <p>Scottsdale, AZ 85255</p>
            <p>Phone: (480) 508-8715</p>
            <p>Fax: (480) 745-3548</p>
          </div>
        </div>
        <div className="top-second">
          <img
            src={require("../../../../assests/images/chatbot/new_logo_blue-border.png")}
            alt="footerlogo"
          />
          <div className="top-second-social-media">
            <span>
              <SocialIcon
                style={{ width: 40, height: 40 }}
                bgColor="grey"
                url="https://www.facebook.com/sleeplessinarizona/"
              ></SocialIcon>
            </span>
            <span>
              <SocialIcon
                style={{ width: 40, height: 40 }}
                bgColor="grey"
                url="https://www.youtube.com/c/Sleeplessinarizona/videos"
              ></SocialIcon>
            </span>
          </div>
        </div>
        <div className="top-third">
          <p className="location-title">
            THE INSOMNIA AND SLEEP INSTITUTE OF ARIZONA
          </p>
          <p className="location-title"> GILBERT</p>
          <div className="location-address">
            <p>1530 E Williams Field Road, Suite 204</p>
            <p>Gilbert, AZ 85295</p>
            <p> Phone: (480) 508-8715</p>
            <p> Fax: (480) 745-3548 </p>
          </div>
        </div>
      </div>
      <div className="footer-mid-section section">
        <img
          className="footer-mid-firstimage"
          src={require("../../../../assests/images/chatbot/AASM-Accredited-Facility-Member_H_black.jpg")}
          alt=""
        />
        <img
          className="footer-mid-secondimage"
          src={require("../../../../assests/images/chatbot/Excellence-Program-Logo1.jpg")}
          alt=""
        />
        <img
          className="footer-mid-thirdimage"
          src={require("../../../../assests/images/chatbot/Top_Doc_Logos-Group.jpg")}
          alt=""
        />
      </div>
      <div className="footer-bottom-section section">
        <p> ©2021 THE INSOMNIA AND SLEEP INSTITUTE OF ARIZONA </p>
        <p>
          {" "}
          IMAGES CONTAINED WITHIN THE WEBSITE ARE A COMBINATION OF ACTUAL
          PATIENTS AND MODELS. INDIVIDUAL RESULTS MAY VARY.
        </p>
      </div>
    </FooterParent>
  );
};
export default Footer;
