import React from "react";
import "./botIcon.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const ShowBot = (props) => {
  const { changeBotState, showBot } = props;

  function handleOnClick() {
    changeBotState(!showBot);
  }
  return (
    <div>
      {document.getElementById("chatTitle") ? (
        <div id="popup_chat">
          <div className="outsidePopupContainer">
            <p>
              {document.getElementById("chatTitle").getAttribute("data-attr")}
            </p>
          </div>
        </div>
      ) : null}

      <div
        id="onHideIcon"
        className="chatbot_icon_div_onHide chat-bot-icon"
        onClick={handleOnClick}
      >
        <img
          className="chatbot_icon_image_onHide"
          alt="chatbot_icon"
          src={require("../../../assests/images/chatbot/new_logo_white-border.png")}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    showBot: state.chatbotReducer.showBot,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeBotState: (value) => {
      dispatch({ type: "CHANGE_BOT_STATE", payload: value });
    },
  };
};

ShowBot.propTypes = {
  changeBotState: PropTypes.func,
  showBot: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowBot);
