import React from "react";
import PropTypes from "prop-types";
import ChatbotIcon from "../chatbot/botIcon/botIcon";
import Header from "../chatbot/Header/header";
import ChatbotBody from "./chatbotBody/chatbotBody";
import Footer from "./Footer/footer";
import "./chatbot.css";
import { connect } from "react-redux";
import * as actionTypes from "../../reduxStore/actions/action";

function Chatbot(props) {
  const { showBot } = props;
  // console.log("PHP PAGE TITLE CHATBOT",document.getElementById("chatTitle"))
  return (
    <div>
      {showBot === true ? (
        <div className="chat-window">
          <Header />
          <ChatbotBody />
          <Footer />
        </div>
      ) : null}

      <ChatbotIcon />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    showBot: state.chatbotReducer.showBot,
  };
};

Chatbot.propTypes = {
  showBot: PropTypes.bool,
};

export default connect(mapStateToProps, actionTypes)(Chatbot);
