import React from "react";
import "./images.css";

const Images = (props) => {
    return props.payload.map((imageUrl, index) => {
        return (
            <div className="messageTextBot" key={index}>
                <img
                    className="imagesStyling"
                    src={imageUrl.structValue.fields.image.stringValue}
                    alt={imageUrl.structValue.fields.description.stringValue}
                ></img>
            </div>
        );
    });
};

export default Images;
