import React from "react";
import {
  Wrapper,
  FeaturesContainer,
  Feature,
  FeatureImg,
} from "./Features.style";

const Features = () => {
  const features = [
    // {
    //   img: require("../../../../assests/images/chatbot/Vector.png"),
    //   text: "AI Powered",
    // },
    {
      img: (
        <svg
          width="73"
          height="65"
          viewBox="0 0 73 65"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M26.3611 0C22.5717 0 19.4286 2.60254 18.5288 6.11914C18.4274 6.11914 18.3514 6.09375 18.25 6.09375C13.7762 6.09375 10.1389 9.7373 10.1389 14.2188C10.1389 14.8281 10.2149 15.4248 10.3543 15.9961C6.65365 17.5195 4.05556 21.1504 4.05556 25.3906C4.05556 26.9902 4.46111 28.4756 5.10746 29.8213C2.0658 31.5732 0 34.8232 0 38.5938C0 42.8213 2.58542 46.4521 6.26076 47.9756C6.1467 48.5596 6.08333 49.1562 6.08333 49.7656C6.08333 54.8184 10.1642 58.9062 15.2083 58.9062C15.728 58.9062 16.2349 58.8428 16.7292 58.7539C17.9458 62.3721 21.317 65 25.3472 65C30.3913 65 34.4722 60.9121 34.4722 55.8594V8.125C34.4722 3.64355 30.8349 0 26.3611 0ZM73 38.5938C73 34.8232 70.9342 31.5732 67.8925 29.8213C68.5516 28.4756 68.9444 26.9902 68.9444 25.3906C68.9444 21.1504 66.3464 17.5195 62.6457 15.9961C62.7724 15.4248 62.8611 14.8281 62.8611 14.2188C62.8611 9.7373 59.2238 6.09375 54.75 6.09375C54.6486 6.09375 54.5599 6.11914 54.4712 6.11914C53.5714 2.60254 50.4283 0 46.6389 0C42.1651 0 38.5278 3.63086 38.5278 8.125V55.8594C38.5278 60.9121 42.6087 65 47.6528 65C51.683 65 55.0542 62.3721 56.2708 58.7539C56.7651 58.8428 57.272 58.9062 57.7917 58.9062C62.8358 58.9062 66.9167 54.8184 66.9167 49.7656C66.9167 49.1562 66.8533 48.5596 66.7392 47.9756C70.4146 46.4521 73 42.8213 73 38.5938Z"
            fill="#1CA7EC"
          />
        </svg>
      ),
      text: "AI Powered",
    },
    {
      img: (
        <svg
          width="73"
          height="65"
          viewBox="0 0 73 65"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M36.5 0C16.3395 0 0 13.5078 0 30.1786C0 37.375 3.05117 43.9621 8.12695 49.1417C6.34473 56.4542 0.384961 62.9688 0.313672 63.0413C0 63.375 -0.0855469 63.8683 0.0998047 64.3036C0.285156 64.7388 0.684375 65 1.14062 65C10.5936 65 17.6797 60.3862 21.1871 57.5424C25.8494 59.327 31.025 60.3571 36.5 60.3571C56.6605 60.3571 73 46.8493 73 30.1786C73 13.5078 56.6605 0 36.5 0ZM18.25 34.8214C15.7264 34.8214 13.6875 32.7467 13.6875 30.1786C13.6875 27.6105 15.7264 25.5357 18.25 25.5357C20.7736 25.5357 22.8125 27.6105 22.8125 30.1786C22.8125 32.7467 20.7736 34.8214 18.25 34.8214ZM36.5 34.8214C33.9764 34.8214 31.9375 32.7467 31.9375 30.1786C31.9375 27.6105 33.9764 25.5357 36.5 25.5357C39.0236 25.5357 41.0625 27.6105 41.0625 30.1786C41.0625 32.7467 39.0236 34.8214 36.5 34.8214ZM54.75 34.8214C52.2264 34.8214 50.1875 32.7467 50.1875 30.1786C50.1875 27.6105 52.2264 25.5357 54.75 25.5357C57.2736 25.5357 59.3125 27.6105 59.3125 30.1786C59.3125 32.7467 57.2736 34.8214 54.75 34.8214Z"
            fill="#1CA7EC"
          />
        </svg>
      ),
      text: "Friendly and Interactive Conversation",
    },
    {
      img: (
        <svg
          width="69"
          height="69"
          viewBox="0 0 69 69"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M69 34.5C69 53.558 53.5525 69 34.5 69C15.4475 69 0 53.558 0 34.5C0 15.4531 15.4475 0 34.5 0C53.5525 0 69 15.4531 69 34.5ZM35.4258 11.4073C27.8446 11.4073 23.0093 14.6009 19.2123 20.2768C18.7204 21.0122 18.885 22.0039 19.59 22.5385L24.4171 26.1986C25.1412 26.7477 26.1728 26.617 26.7354 25.9034C29.2205 22.7514 30.9245 20.9236 34.707 20.9236C37.5489 20.9236 41.0642 22.7526 41.0642 25.5084C41.0642 27.5918 39.3443 28.6617 36.5383 30.2349C33.2658 32.0694 28.9355 34.3527 28.9355 40.0645V40.621C28.9355 41.5429 29.6829 42.2903 30.6048 42.2903H38.3952C39.3171 42.2903 40.0645 41.5429 40.0645 40.621V40.4355C40.0645 36.4761 51.6368 36.3112 51.6368 25.5968C51.6368 17.5279 43.267 11.4073 35.4258 11.4073ZM34.5 45.9073C30.9714 45.9073 28.1008 48.7779 28.1008 52.3065C28.1008 55.8349 30.9714 58.7056 34.5 58.7056C38.0286 58.7056 40.8992 55.8349 40.8992 52.3065C40.8992 48.7779 38.0286 45.9073 34.5 45.9073Z"
            fill="#1CA7EC"
          />
        </svg>
      ),
      text: "Sleep Disorder Knowledgebase",
    },
    {
      img: (
        <svg
          width="71"
          height="51"
          viewBox="0 0 71 51"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M46.15 34H51.475C52.3625 34 53.25 33.15 53.25 32.3V14.45C53.25 13.6 52.3625 12.75 51.475 12.75H46.15C45.2625 12.75 44.375 13.6 44.375 14.45V32.3C44.375 33.15 45.2625 34 46.15 34ZM59.4625 34H64.7875C65.675 34 66.5625 33.15 66.5625 32.3V1.7C66.5625 0.85 65.675 0 64.7875 0H59.4625C58.575 0 57.6875 0.85 57.6875 1.7V32.3C57.6875 33.15 58.575 34 59.4625 34ZM19.525 34H24.85C25.7375 34 26.625 33.15 26.625 32.3V22.95C26.625 22.1 25.7375 21.25 24.85 21.25H19.525C18.6375 21.25 17.75 22.1 17.75 22.95V32.3C17.75 33.15 18.6375 34 19.525 34ZM32.8375 34H38.1625C39.05 34 39.9375 33.15 39.9375 32.3V5.95C39.9375 5.1 39.05 4.25 38.1625 4.25H32.8375C31.95 4.25 31.0625 5.1 31.0625 5.95V32.3C31.0625 33.15 31.95 34 32.8375 34ZM68.7812 42.5H8.875V2.125C8.875 0.950938 7.88211 0 6.65625 0H2.21875C0.992891 0 0 0.950938 0 2.125V46.75C0 49.0968 1.98717 51 4.4375 51H68.7812C70.0071 51 71 50.0491 71 48.875V44.625C71 43.4509 70.0071 42.5 68.7812 42.5Z"
            fill="#1CA7EC"
          />
        </svg>
      ),
      text: "View Potential Diagnosis",
    },
  ];
  return (
    <Wrapper>
      <h1>Features</h1>
      <FeaturesContainer>
        {features.map((feature) => {
          return (
            <Feature>
              {feature.img}
              <p>{feature.text}</p>
            </Feature>
          );
        })}
      </FeaturesContainer>
    </Wrapper>
  );
};
export default Features;
