import React from "react";
import Chatbot from "../src/components/chatbot/chatbot";
import Landing from "../src/components/Landing/Landing";
import "./index.css";
import { render } from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
//react-redux-libraries
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
//
import rootReducer from "./reduxStore/reducers/combineReducer";

import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
const middleware = [thunk, logger];
const composeEnacher = composeWithDevTools({ trace: true, traceLimit: 25 });
const store = createStore(
  rootReducer,
  composeEnacher(applyMiddleware(...middleware))
);

render(
  <Provider store={store}>
    <Landing />
    <Chatbot />
  </Provider>,
  document.getElementById("root")
);

/*
{
  "quick_replies": [
    {
      "payload": "Ok",
      "text": "Okay",
      "size": "normal"
    },
    {
      "payload": "No",
      "size": "normal",
      "text": "No"
    }
  ]
}

*/
