import styled from "styled-components";

export const TopHeader = styled.div`
	position: relative;
	max-width: 100%;
	padding: 10px 60px;
	margin: 0px auto;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	background-color: #3ea7ec;
	@media (max-width: 460px) {
		justify-content: center;
		padding:10px 20px;
		#heading {
			display: none;
		}
	}
`;

export const LogoImage = styled.div`
	position: relative;
	margin: auto 0;
	display: flex;
	text-align: left;
	align-items: center;
    justify-content: left;
    flex-direction: row;
	width: 100%;
	p {
	margin: 0;
	margin-left:10px;
	font-size: 18px;
	color: white;
	font-weight: bold;
	}
	img {
		max-width: 50px;
		/* height: 60px; */
	}
	@media screen and (max-width: 460px) {

		img {
			width: 40px;
			object-fit: contain;
		}

		p {
		font-size: 14px;
		max-width: fit-content;
		}


	}

    @media screen and (max-width: 360px) {

	
		img {
			width: 40px;
			object-fit: contain;
		}

		p {
		font-size: 12px;
		max-width: fit-content;
		}


	}
`;

export const LogoHeading = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center; ;
`;

export const LogoMainHeading = styled.div`
	position: relative;
	p {
		color: #fff;
		font-size: 16px;
		margin-bottom: 0;
	}
`;

export const LogoSubHeading = styled.div`
	margin-top: 10px;
	position: relative;
	p {
		color: #fff;
		font-size: 12px;
		margin-bottom: 0;
	}
`;

export const ExtraSpace = styled.div`
	position: relative;
	width: 100%;
	padding: 5px 100px;
	margin: 0px auto;
	display: flex;
	background-color: #3ea7ec;
`;
