import styled from "styled-components";
import banner from "../../../../assests/images/chatbot/Avatar_sleep_1.png";
import otherbanner from "../../../../assests/images/chatbot/Mask_Group.png";

export const HeroSection = styled.div`
	position: relative;
	max-width: 100%;
	padding: 10px 30px;
	margin: 0px auto;
	display: flex;
	flex-wrap: wrap;
	height: 450px;
	align-items: center;
	justify-content: space-between;
	background: url(${banner});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: top right;
	margin-bottom: 40px;
	@media (max-width: 768px) {
		height: 380px;
	}
	@media (max-width: 460px) {
		height: 380px;
		background: url(${otherbanner});
		background-size: 200px;
		background-repeat: no-repeat;
		background-position: top right;
	}
`;

export const HeroSectionLeft = styled.div`
	position: relative;
	width: 40%;
	padding: 10px 30px;
	margin: 0px auto 0 60px;
	height: 400px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;

	h1 {
		color: #3ea7ec;
		font-size: 2rem;
		line-height: 1.3;
		margin-bottom: 0;
	}
	p {
		line-height: 1.6;
		font-size: 18px;
	}
	button {
		background-color: #3ea7ec;
		color: white;
		outline: none;
		border: none;
		padding: 10px 40px;
		border-radius: 10px;
		font-size: 20px;
	}
	@media (min-width: 769px) {
		/* margin-top: -110px; */
		p {
			margin-bottom: 0rem;
		}
	}

	@media (max-width: 768px) {
		width: 50%;
		margin: 0px auto 0 20px;
		p {
			font-size: 14px;
		}
		button {
			font-size: 16px;
			padding: 10px 30px;
		}
	}
	@media (max-width: 460px) {
		width: 60%;
		padding: 0;
		margin: 0px auto 0 0px;
		h1 {
			font-size: 1.5rem;
		}
		p {
			line-height: 1.5;
			font-size: 14px;
		}
		button {
			font-size: 12px;
		}
	}
	@media (max-width: 320px) {
		h1 {
			line-height: 1.3;
		}
	}
`;

export const HeroSectionRight = styled.div`
	position: relative;
	width: 10%;
	padding: 10px 30px;
	margin: 0px auto;
	height: 400px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	@media (max-width: 768px) {
		width: 40%;
	}
`;
