import React from "react";
import { ListGroup } from "react-bootstrap";
import PropTypes from "prop-types";
import "./listView.css";

const ListView = (props) => {
    const { payload } = props;
    return (
        <ListGroup className="messageTextBot listParent">
            {payload.map((listItems, index) => {
                return (
                    <div className="items" id="listview" key={index}>
                        <div id={listItems.structValue.fields.payload.stringValue}>
                            <ListGroup.Item style={{ paddingTop: 2, paddingBottom: 2 }}>
                                {listItems.structValue.fields.sno.stringValue}.{"  "}
                                {listItems.structValue.fields.text.stringValue}
                            </ListGroup.Item>
                        </div>
                    </div>
                );
            })}
        </ListGroup>
    );
};


ListView.propTypes = {
    payload: PropTypes.array
};
export default ListView;
