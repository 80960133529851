import React, { useEffect } from "react";
import "./Landing.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actionTypes from "../../reduxStore/actions/action";
import Features from "./components/Features/index";
import HowItWorks from "./components/HowItWorks";
import TopBanner from "./components/TopBanner/";
import HeroSection from "./components/HeroSection";
import Footer from "./components/Footer";

const Landing = (props) => {
  const { changeBotState, showBot } = props;
  useEffect(()=>{
  },[])
  const showChatBot = () => {
    changeBotState(!showBot);
  };

  return (
    <>
      <TopBanner />
      <HeroSection />
      <HowItWorks />
      <Features />
      {/* <Footer /> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    showBot: state.chatbotReducer.showBot,
  };
};

Landing.propTypes = {
  showBot: PropTypes.bool,
  dfTestQuery: PropTypes.func,
};

export default connect(mapStateToProps, actionTypes)(Landing);
